<script setup>
import {VLayout} from "vuetify/components";
import {Link, router, usePage} from "@inertiajs/vue3";
import {computed, inject, onMounted, ref} from "vue";
import {useDisplay, useTheme} from "vuetify";
import {Companies} from "@/Helper/exclusion-helper.js";
import {reloadGlobalAuthProp} from "@/helper.js";
import UserAvatar from "@/Components/UserAvatar.vue";

const {mobile} = useDisplay()

const page = usePage()
const user = computed(() => page.props.auth.user)
const branding = computed(() => page.props.branding)

const unreadChatMessagesCount = computed(() => page.props.auth.user.unread_chat_messages_count ?? 0)

const theme = useTheme()
theme.themes.value.joiny.colors.primary = branding.value.color_primary
theme.themes.value.joiny.colors["on-primary"] = branding.value.color_on_primary

const secondaryNavbarContent = ref()
const primaryNavigationOpen = ref(false)

const eventBus = inject('eventBus')
eventBus.on('navBarChanged', content => {
    secondaryNavbarContent.value = content
})


const consoleLinks = ref([
    {
        title: [Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.value.company_id) ? 'Revenews' : 'Community',
        routeName: "community.index",
        match: "community",
        icon: "mdi-comment-quote-outline",
        visibility: page.props.features?.community._active,
        disabled: !user.value.company.has_active_subscription,
    },
    /*{
      title: "Dashboard",
      path: "/dashboard",
      icon: "mdi-view-dashboard-outline"
    },*/
    {
        title: "Chat",
        routeName: "chat",
        match: "chat",
        icon: "mdi-chat-processing-outline",
        visibility: page.props.features?.chat._active,
        disabled: !user.value.company.has_active_subscription,
        badge: unreadChatMessagesCount
    },
    {
        title: "Kunden",
        routeName: "customers",
        match: "customers",
        icon: "mdi-account-multiple-outline",
        visibility: page.props.features?.customers._active && user.value.permissions.includes('access_customers'),
        disabled: !user.value.company.has_active_subscription,
    },
    {
        title: [Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.value.company_id) ? 'Termine' : 'Projekte',
        routeName: "projects",
        match: "projects",
        icon: [Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.value.company_id) ? 'mdi-calendar-today-outline' : "mdi-graph-outline",
        visibility: page.props.features?.projects._active && user.value.permissions.includes('access_projects'),
        disabled: !user.value.company.has_active_subscription,
    },
    {
        title: "Onboarding",
        routeName: "onboardings",
        match: "onboardings",
        icon: "mdi-airplane-takeoff",
        visibility: user.value.permissions.includes('access_onboardings') && [Companies.VALUEVNTRS, Companies.JOINY_DEV].includes(user.value.company_id),
        disabled: !user.value.company.has_active_subscription,
    },
    {
        title: "Zahlung",
        routeName: "payments",
        match: "payments",
        icon: "mdi-credit-card-outline",
        visibility: page.props.features?.payments._active && user.value.permissions.includes('access_payments'),
        disabled: !user.value.company.has_active_subscription,
    },
    {
        title: "Dateien",
        routeName: "files",
        match: "files",
        icon: "mdi-folder-open-outline",
        visibility: page.props.features?.files._active && user.value.permissions.includes('access_files'),
        disabled: !user.value.company.has_active_subscription,
    },
    /*{
      title: "Automation",
      path: "/automation",
      icon: "mdi-robot-love-outline"
    },*/
    {
        title: "Einstellungen",
        routeName: "settings.index",
        match: "settings",
        icon: "mdi-cog-outline",
        visibility: user.value.permissions.includes('access_settings')
    }
])

const portalLinks = ref([
    {
        title: [Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.value.company_id) ? 'Revenews' : 'Community',
        routeName: "community.index",
        match: "community",
        icon: "mdi-comment-quote-outline",
        visibility: true,
    },
    {
        title: "Chat",
        routeName: "chat",
        match: "chat",
        icon: "mdi-chat-processing-outline",
        visibility: page.props.features?.chat._active,
        disabled: !user.value.company.has_active_subscription,
        badge: unreadChatMessagesCount
    },
    {
        title: [Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.value.company_id) ? 'Termine' : 'Projekte',
        routeName: "projects",
        match: "projects",
        icon: [Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.value.company_id) ? 'mdi-calendar-today-outline' : "mdi-graph-outline",
        visibility: page.props.features?.projects._active,
        disabled: !user.value.company.has_active_subscription,
    },
    {
        title: "Onboarding",
        routeName: "onboardings.index-portal",
        match: "onboardings",
        icon: "mdi-airplane-takeoff",
        visibility: [Companies.VALUEVNTRS, Companies.JOINY_DEV].includes(user.value.company_id),
        disabled: !user.value.company.has_active_subscription,
    },
    {
        title: "Dateien",
        routeName: "files",
        match: "files",
        icon: "mdi-folder-open-outline",
        visibility: page.props.features?.files._active,
        disabled: !user.value.company.has_active_subscription,
    },
])

const links = computed(() => {
    switch (user.value.tenant) {
        case 'portal':
            return portalLinks

        case 'console':
            return consoleLinks

        default:
            return consoleLinks
    }
})

onMounted(() => {
    Echo.private(`chat.${user.value.chat_participant.id}`)
        .listen('ChatMessageReceived', event => {
            reloadGlobalAuthProp(page)
        })
})
</script>

<template>
    <v-layout class="w-100">
        <v-navigation-drawer
            :model-value="!mobile || primaryNavigationOpen"
            @update:model-value="primaryNavigationOpen = $event"
            :width="160"
        >
            <v-list
                id="main-menu"
                :nav="true"
                :slim="true"
            >
                <v-list-item
                    class="text-center ma-0"
                    style="height: 65px"
                >
                    <div style="height: 100%; width: 100%">
                        <Link href="/" style="position: relative">
                            <img
                                :src="branding.logo_url ?? '/img/logo.webp'"
                                alt="logo"
                                style="max-width: 100px; height: 50px; object-fit: contain"
                            />
                        </Link>
                    </div>
                </v-list-item>

                <v-list-item
                    v-for="link in links.value.filter(l => l.visibility)"
                    :key="link"
                    :class="{ 'v-list-item--active': route().current().startsWith(link?.match) }"
                    :title="link?.title"
                    :disabled="link.disabled"
                    @click.prevent.stop="router.visit(route(link?.routeName))"
                >
                    <template #prepend>
                        <v-badge color="red" :model-value="link?.badge > 0" :content="link?.badge">
                            <v-icon :icon="link?.icon"/>
                        </v-badge>
                    </template>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar class="px-3 border-b" :flat="true" :absolute="true" :permanent="true">
            <!--      <v-tabs centered color="grey-darken-2">-->
            <!--        <v-tab-->
            <!--            v-for="link in links"-->
            <!--            :key="link.path"-->
            <!--            class="text-none"-->
            <!--            :text="link.title"-->
            <!--            :prepend-icon="link.icon"-->
            <!--            :to="link.path"-->
            <!--        />-->
            <!--      </v-tabs>-->
            <div class="d-flex align-center ga-2">
                <v-app-bar-nav-icon
                    v-if="mobile"
                    variant="text"
                    @click.stop="primaryNavigationOpen = !primaryNavigationOpen"
                />
                <v-icon
                    v-if="secondaryNavbarContent?.back"
                    icon="mdi-arrow-left"
                    size="small"
                    variant="tonal"
                    @click="router.visit(secondaryNavbarContent?.back)"
                />
                <h3>{{ secondaryNavbarContent?.title }}</h3>
            </div>

            <v-spacer/>

            <v-badge color="red" dot class="me-3" v-if="false">
                <v-icon icon="mdi-bell" size="large" color="grey-darken-1"/>
            </v-badge>

            <div class="d-flex align-center ga-2">
                <v-tooltip
                    text="Support"
                    location="bottom"
                >
                    <template #activator="{ props }">
                        <v-btn
                            icon="mdi-face-agent"
                            v-bind="props"
                            @click="window.open('mailto:support@joiny.io', '_blank')"
                        />
                    </template>
                </v-tooltip>

                <v-menu
                    :open-on-click="true"
                    :open-delay="100"
                    :close-on-content-click="false"
                >
                    <template #activator="{ props }">
                        <div
                            class="cursor-pointer"
                            v-bind="props"
                        >
                            <user-avatar
                                :user="user"
                                size="45"
                                disable-tooltip
                            />
                        </div>
                    </template>

                    <v-list>
                        <v-list-item
                            title="Team"
                            :subtitle="user.company.name"
                        >
                            <template v-slot:append>
                                <v-icon icon="mdi-menu-right" size="x-small"></v-icon>
                            </template>

                            <v-menu :open-on-focus="false" activator="parent" open-on-hover submenu>
                                <v-list>
                                    <v-list-item
                                        v-for="company in user.companies"
                                        :key="company.id"
                                        :title="company.name"
                                        :prepend-icon="user.company.id === company.id ? 'mdi-check' : null"
                                        :disabled="user.company.id === company.id"
                                        @click="router.patch(route('change-team', {team: company.id}))"
                                    />
                                </v-list>
                            </v-menu>
                        </v-list-item>

                        <v-divider/>

                        <v-list-item
                            :title="`${user.firstname} ${user.lastname}`"
                            subtitle="Profil"
                            @click="router.visit(route('profile.index'))"
                        />

                        <v-list-item
                            title="Logout"
                            prepend-icon="mdi-logout"
                            @click="router.post(route('logout'))"
                        />
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>

        <v-main class="bg-grey-lighten-5">
            <slot/>
        </v-main>
    </v-layout>
</template>

<style>
#main-menu .v-list-item__overlay {
    background: none;
}

#main-menu .v-list-item--active .v-list-item__overlay,
.v-avatar--variant-tonal .v-avatar__underlay {
    background: rgb(var(--v-theme-primary)) !important;
    opacity: 0.25 !important;
}

#main-menu .v-list-item--active .v-list-item__content,
.v-avatar--variant-tonal > i {
    z-index: 1;
}

.v-card--variant-outlined {
    border-color: rgba(var(--v-theme-primary), 0.3);
    border-width: medium;
}
</style>
