<script setup>
import {computed} from "vue";

const props = defineProps({
    name: {
        default: undefined,
        type: String
    },
    user: {
        default: undefined,
        type: Object
    },
    size: {
        default: '48',
        type: String
    },
    disableTooltip: {
        default: false,
        type: Boolean
    }
})

const fontSize = computed(() => {
    const sizeNumber = parseInt(props.size)

    if (['x-small', 'small'].includes(props.size)) {
        return '12px'
    } else if (sizeNumber) {
        return `${sizeNumber * 0.4}px`
    } else {
        return 'inherit'
    }
})

const nameInitials = computed(() => {
    const splittedName = props.name.split(' ')
    const firstLetter = [...splittedName[0]][0]
    const secondLetter = [...(splittedName[1] ?? [])][0]

    return `${firstLetter}${secondLetter ?? ''}`
})
</script>

<template>
    <v-tooltip
        location="bottom"
        :text="`${user?.firstname} ${user?.lastname}`"
        :disabled="props.disableTooltip || !user"
    >
        <template #activator="{props}">
            <v-avatar
                v-if="user?.profile_picture_url"
                v-bind="props"
                :image="user.profile_picture_url"
                :size="size"
            />

            <v-avatar
                v-else
                v-bind="props"
                :color="user?.color ?? 'primary'"
                :size="size"
            >
                <span
                    v-if="user"
                    class="font-weight-bold"
                    style="user-select: none;"
                    :style="{ 'font-size': fontSize }"
                >
                    {{ user.firstname?.slice(0, 1) }}{{ user.lastname?.slice(0, 1) }}
                </span>

                <span
                    v-else-if="name"
                    class="font-weight-bold"
                    style="user-select: none;"
                    :style="{ 'font-size': fontSize }"
                >
                    {{ nameInitials }}
                </span>
            </v-avatar>
        </template>
    </v-tooltip>


</template>
