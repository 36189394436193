<script setup>

import {computed, inject, ref} from "vue";
import {Head, router, useForm, usePage} from "@inertiajs/vue3";
import Default from "@/Layouts/Default.vue";
import UserAvatar from "@/Components/UserAvatar.vue";
import {de} from 'date-fns/locale';
import {validationRules} from "@/helper.js";
import DeletePostDialog from "@/Components/Community/DeletePostDialog.vue";
import DeleteCommentDialog from "@/Components/Community/DeleteCommentDialog.vue";
import {isAdministrator} from "@/Helper/authorization-helper.js";
import {Companies} from "@/Helper/exclusion-helper.js";

defineOptions({layout: Default})

const props = defineProps({
    users: Array,
    posts: Object,
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const newPostViewActive = ref(false)
const newPostForm = ref()
const newPostImageInput = ref()
const newPostFileInput = ref()
const descriptionEditor = ref()

const selectedPost = ref()
const deletePostDialog = ref(false)
const deletePostDialogLoading = ref(false)
const deleteCommentDialog = ref(false)

const newPostImagePreview = computed(() => URL.createObjectURL(postForm.image))

const postForm = useForm({
    title: undefined,
    description: undefined,
    image: undefined,
    file: undefined,
})

const commentForm = useForm({
    description: undefined
})

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: [Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.value.company_id) ? 'Revenews' : 'Community'})

function toggleLike(postId) {
    router.patch(route('community.posts.toggle-like', {post: postId}), {}, {
        preserveScroll: true
    })
}

function createComment(post) {
    commentForm.description = post.newCommentDescription

    commentForm.post(route('community.posts.comments.store', {post: post.id}), {
        onSuccess: () => post.newCommentContent = undefined,
        preserveScroll: true
    })
}

async function createPost() {
    postForm.clearErrors()
    await newPostForm.value.validate()
    if (!newPostForm.value.isValid) return

    postForm.post(route('community.posts.store'), {
        onSuccess: () => {
            newPostViewActive.value = false
            postForm.reset()
        }
    })
}

function deletePost() {
    deletePostDialogLoading.value = true

    router.delete(route('community.posts.destroy', {post: selectedPost.value.id}), {
        onSuccess: () => {
            deletePostDialog.value = false
        },
        onFinish: () => deletePostDialogLoading.value = false
    })
}

function deleteComment() {
    deletePostDialogLoading.value = true

    router.delete(route('community.posts.comments.destroy', {
        post: selectedPost.value.parent,
        comment: selectedPost.value.id
    }), {
        onSuccess: () => {
            deleteCommentDialog.value = false
        },
        onFinish: () => deletePostDialogLoading.value = false
    })
}
</script>

<template>
    <Head :title="[Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.company_id) ? 'Revenews' : 'Community'"/>

    <v-container
        fluid
        class="d-flex flex-column ga-5 align-center h-100"
        style="overflow-y: scroll; max-width: 1200px"
    >
        <div
            v-if="user.permissions.includes('create_community_post')"
            class="flex-shrink-0 w-100"
        >
            <v-card
                v-if="newPostViewActive"
                class="pa-3"
                border
            >
                <div class="d-flex ga-5">
                    <UserAvatar
                        :user="user"
                        :disable-tooltip="true"
                    />

                    <v-form
                        ref="newPostForm"
                        class="d-flex flex-column flex-grow-1 ga-3 pe-5"
                        validate-on="submit"
                        @submit.prevent="createPost"
                    >
                        <div class="d-flex ga-5">
                            <div class="flex-grow-1">
                                <v-text-field
                                    id="new-post-title-textfield"
                                    v-model="postForm.title"
                                    :rules="[validationRules.required]"
                                    placeholder="Titel"
                                    variant="plain"
                                    hide-details
                                    autofocus
                                    :disabled="postForm.processing"
                                />

                                <VuetifyTiptap
                                    ref="descriptionEditor"
                                    v-model="postForm.description"
                                    :rules="[validationRules.required]"
                                    rounded="false"
                                    hide-bubble
                                    output="html"
                                    :disabled="postForm.processing"
                                />
                            </div>

                            <div
                                v-if="postForm.image"
                                style="max-width: 25%; position: relative"
                            >
                                <img
                                    :src="newPostImagePreview"
                                    alt=""
                                    style="width: 100%; border-radius: 25px;"
                                />

                                <v-btn
                                    icon="mdi-close"
                                    variant="tonal"
                                    color="red"
                                    style="position: absolute; right: 10px; top: 10px"
                                    size="small"
                                    @click="postForm.reset('image'); newPostImageInput.value = ''"
                                />
                            </div>
                        </div>

                        <div class="d-flex ga-2">
                            <v-btn
                                icon="mdi-image-plus-outline"
                                density="compact"
                                :disabled="postForm.processing"
                                @click.prevent="newPostImageInput.click()"
                            />
                            <input
                                ref="newPostImageInput"
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                hidden
                                @change="postForm.image = $event.target.files[0]"
                                alt=""
                            >

                            <v-btn
                                v-if="false"
                                icon="mdi-paperclip-plus"
                                density="compact"
                                :disabled="postForm.processing"
                            />
                        </div>

                        <hr>

                        <div class="d-flex flex-column flex-grow-1 align-end">
                            <div>
                                <v-btn
                                    text="Abbrechen"
                                    :disabled="postForm.processing"
                                    @click="newPostViewActive = false; postForm.reset(); newPostImageInput.value = ''"
                                />

                                <v-btn
                                    text="Posten"
                                    variant="tonal"
                                    type="submit"
                                    :loading="postForm.processing"
                                    :disabled="!postForm.title || descriptionEditor?.editor.isEmpty"
                                />
                            </div>
                        </div>
                    </v-form>
                </div>
            </v-card>

            <v-card
                v-else
                class="pa-3 cursor-pointer flex-shrink-0 w-100"
                border
                @click="newPostViewActive = true"
            >
                <div class="d-flex ga-5 align-center">
                    <UserAvatar
                        :user="user"
                        :disable-tooltip="true"
                    />

                    <span class="text-h6 text-grey-darken-2">Schreibe etwas ...</span>
                </div>
            </v-card>
        </div>

        <v-card
            v-for="post in posts.data"
            class="pa-3 flex-shrink-0 w-100"
            border
        >
            <div class="d-flex flex-column ga-5">
                <div class="d-flex ga-5">
                    <UserAvatar
                        :user="post.user"
                        :disable-tooltip="true"
                    />

                    <div class="d-flex flex-column ga-3 pe-5">
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">
                                {{ post.user?.firstname }}&nbsp;{{
                                    post.user?.lastname
                                }}&nbsp;@&nbsp;{{ post.user?.company?.name }}
                            </span>

                            <timeago
                                class="text-caption text-grey-darken-2"
                                :datetime="new Date(post.created_at)"
                                :locale="de"
                            />
                        </div>

                        <div class="d-flex ga-3">
                            <div class="d-flex flex-column ga-3 flex-grow-1">
                                <span class="text-h5 font-weight-bold">{{ post.title }}</span>
                                <span v-html="post.description" />
                            </div>

                            <div
                                v-if="post.image_url"
                                class="flex-shrink-0"
                                style="max-width: 25%"
                            >
                                <img
                                    :src="post.image_url"
                                    alt=""
                                    style="width: 100%; border-radius: 25px;"
                                >
                            </div>
                        </div>
                    </div>

                    <v-spacer/>

                    <v-menu
                        v-if="post.user?.id === user.id || isAdministrator(user)"
                        :open-on-click="true"
                        :open-delay="100"
                    >
                        <template #activator="{ props }">
                            <v-btn
                                icon="mdi-dots-vertical"
                                v-bind="props"
                            />
                        </template>

                        <v-list>
                            <v-list-item
                                title="Löschen"
                                prepend-icon="mdi-trash-can-outline"
                                base-color="red"
                                @click="selectedPost = post; deletePostDialog = true"
                            />
                        </v-list>
                    </v-menu>
                </div>

                <div class="d-flex ga-5 text-grey-darken-2">
                    <div class="d-flex ga-1 justify-center align-center">
                        <v-btn
                            :icon="`mdi-thumb-up${post.likes?.map(like => like.user_id).includes(user.id) ? '' : '-outline'}`"
                            variant="text"
                            density="compact"
                            @click="toggleLike(post.id)"
                        />
                        <span class="font-weight-bold">{{ post.likes?.length }}</span>
                        <span>Likes</span>
                    </div>

                    <div class="d-flex ga-1 justify-center align-center">
                        <v-btn
                            :icon="`mdi-chat${post.comments?.map(comment => comment.user_id).includes(user.id) ? '' : '-outline'}`"
                            variant="text"
                            density="compact"
                        />
                        <span class="font-weight-bold">{{ post.comments?.length }}</span>
                        <span>Kommentare</span>
                    </div>
                </div>

                <v-card
                    v-for="comment in post.comments"
                    class="pa-3"
                    border
                >
                    <div class="d-flex flex-column ga-2">
                        <div class="d-flex ga-2 align-center">
                            <UserAvatar
                                :user="comment.user"
                                disable-tooltip
                                size="40"
                            />

                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">
                                    {{ comment.user?.firstname }}&nbsp;{{
                                        comment.user?.lastname
                                    }}&nbsp;@&nbsp;{{ comment.user?.company?.name }}
                                </span>

                                <timeago
                                    class="text-caption text-grey-darken-2"
                                    :datetime="new Date(comment.created_at)"
                                    :locale="de"
                                />
                            </div>

                            <v-spacer/>

                            <v-menu
                                v-if="comment.user?.id === user.id || isAdministrator(user)"
                                :open-on-click="true"
                                :open-delay="100"
                            >
                                <template #activator="{ props }">
                                    <v-btn
                                        icon="mdi-dots-vertical"
                                        v-bind="props"
                                    />
                                </template>

                                <v-list>
                                    <v-list-item
                                        title="Löschen"
                                        prepend-icon="mdi-trash-can-outline"
                                        base-color="red"
                                        @click="selectedPost = comment; deleteCommentDialog = true"
                                    />
                                </v-list>
                            </v-menu>
                        </div>

                        <div class="d-flex ga-3">
                            <div class="d-flex flex-column ga-3">
                                <span>{{ comment.description }}</span>
                            </div>
                        </div>

                        <div class="d-flex ga-5 text-grey-darken-2">
                            <div class="d-flex ga-1 justify-center align-center">
                                <v-btn
                                    :icon="`mdi-thumb-up${comment.likes.map(like => like.user_id).includes(user.id) ? '' : '-outline'}`"
                                    variant="text"
                                    density="compact"
                                    @click="toggleLike(comment.id)"
                                />
                                <span class="font-weight-bold">{{ comment.likes.length }}</span>
                                <span>Likes</span>
                            </div>
                        </div>
                    </div>
                </v-card>

                <div class="d-flex ga-3">
                    <UserAvatar
                        :user="user"
                        size="40"
                        disable-tooltip
                    />

                    <v-textarea
                        v-model="post.newCommentDescription"
                        placeholder="Schreibe einen Kommentar ..."
                        variant="outlined"
                        density="compact"
                        :hide-details="true"
                        rounded
                        auto-grow
                        rows="1"
                        @keydown.enter.exact.prevent="createComment(post)"
                    >
                        <template #append>
                            <div v-if="post.newCommentDescription" class="h-100">
                                <div class="d-flex flex-column h-100 justify-end">
                                    <v-btn
                                        text="Posten"
                                        variant="tonal"
                                        height="40"
                                        @click="createComment(post)"
                                        :loading="commentForm.processing"
                                    />
                                </div>
                            </div>
                        </template>
                    </v-textarea>
                </div>
            </div>
        </v-card>

        <DeletePostDialog
            :is-loading="deletePostDialogLoading"
            :is-open="deletePostDialog"
            @close="deletePostDialog = false"
            @confirm="deletePost"
        />

        <DeleteCommentDialog
            :is-loading="deletePostDialogLoading"
            :is-open="deleteCommentDialog"
            @close="deleteCommentDialog = false"
            @confirm="deleteComment"
        />
    </v-container>
</template>

<style>

#new-post-title-textfield {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 1.25rem;
}

</style>
