<script setup>
import Default from "@/Layouts/Default.vue";
import {validationRules} from "@/helper.js";
import {useToast} from "vue-toastification";
import {inject, ref} from "vue";
import {Head, useForm, usePage, router} from "@inertiajs/vue3";
import UserAvatar from "@/Components/UserAvatar.vue";

defineOptions({layout: [Default]})

const page = usePage()
const user = page.props.auth.user

const toast = useToast()

const userForm = useForm({
    profile_picture: undefined,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
})

const passwordForm = useForm({
    current_password: '',
    password: '',
    password_confirmation: '',
})

const profilePictureInput = ref()
const profilePicturePreviewUrl = ref(undefined)

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Mein Profil'})

function saveData() {
    if (userForm.isDirty) {

        userForm.post(route('profile.update'), {
            onSuccess: () => {
                toast.success('Das Profil wurde erfolgreich aktualisiert 🎉')
            }
        })
    }

    if (passwordForm.isDirty) {
        passwordForm.put(route('password.update'), {
            onSuccess: () => {
                toast.success('Das Passwort wurde erfolgreich geändert 🎉')
                passwordForm.reset()
            }
        })
    }
}

function newProfilePictureSelected(event) {
    userForm.profile_picture = event.target.files[0]

    const fileReader = new FileReader()
    fileReader.onload = function (e) {
        profilePicturePreviewUrl.value = e.target.result
    }
    fileReader.readAsDataURL(userForm.profile_picture)
}

function deleteProfilePicture() {
    router.delete(route('profile.profile-picture.delete'), {
        preserveState: false
    })
}
</script>

<template>
    <Head title="Mein Profil"/>

    <v-row class="flex-grow-0">
        <v-col>
            <v-row>
                <v-col cols="6">
                    <v-card class="pa-5 d-flex flex-column ga-3">
                        <div>
                            <h3>Persönliche Informationen</h3>
                        </div>

                        <v-row>
                            <v-col>
                                <div class="d-flex ga-5 align-center">
                                    <v-avatar
                                        v-if="profilePicturePreviewUrl"
                                        :image="profilePicturePreviewUrl"
                                        size="120"
                                    />

                                    <user-avatar
                                        v-else
                                        :user="user"
                                        size="120"
                                    />

                                    <div class="d-flex flex-column ga-3">
                                        <v-btn
                                            prepend-icon="mdi-cloud-upload-outline"
                                            text="Profilbild hochladen"
                                            variant="outlined"
                                            @click="profilePictureInput.click()"
                                        />

                                        <v-btn
                                            prepend-icon="mdi-trash-can-outline"
                                            text="Profilbild löschen"
                                            color="red"
                                            variant="tonal"
                                            @click="deleteProfilePicture"
                                        />
                                    </div>

                                    <input
                                        ref="profilePictureInput"
                                        hidden="hidden"
                                        type="file"
                                        accept="image/jpeg,image/png"
                                        @change="newProfilePictureSelected"
                                    >
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="userForm.firstname"
                                    label="Vorname"
                                    :error-messages="userForm.errors.firstname"
                                    type="text"
                                    variant="outlined"
                                    hide-details="auto"
                                    :rules="[validationRules.required]"
                                />
                            </v-col>

                            <v-col>
                                <v-text-field
                                    v-model="userForm.lastname"
                                    label="Nachname"
                                    :error-messages="userForm.errors.lastname"
                                    type="text"
                                    variant="outlined"
                                    hide-details="auto"
                                    :rules="[validationRules.required]"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="userForm.email"
                                    label="E-Mail-Adresse"
                                    type="text"
                                    variant="outlined"
                                    :rules="[validationRules.required, validationRules.email]"
                                    disabled
                                    hide-details="auto"
                                    hint="Zum Ändern der E-Mail-Adresse, kontaktieren bitte den Support."
                                    persistent-hint
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="6">
                    <v-card class="pa-5 d-flex flex-column ga-3">
                        <div>
                            <h3>Passwort ändern</h3>
                            <span class="text-caption font-weight-light">Durch das Ändern des Passworts werden alle
                                Geräte abgemeldet.</span>
                        </div>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="passwordForm.current_password"
                                    label="aktuelles Passwort"
                                    autocomplete="current-password"
                                    type="password"
                                    variant="outlined"
                                    hide-details="auto"
                                    :error-messages="passwordForm.errors.current_password"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="passwordForm.password"
                                    label="neues Passwort"
                                    autocomplete="new-password"
                                    type="password"
                                    variant="outlined"
                                    hide-details="auto"
                                    :error-messages="passwordForm.errors.password"
                                />
                            </v-col>

                            <v-col>
                                <v-text-field
                                    v-model="passwordForm.password_confirmation"
                                    label="Passwort wiederholen"
                                    autocomplete="new-password"
                                    type="password"
                                    variant="outlined"
                                    hide-details="auto"
                                    :error-messages="passwordForm.errors.password_confirmation"
                                />
                            </v-col>
                        </v-row>
                    </v-card>

                </v-col>
            </v-row>

            <v-row class="flex-grow-0 pe-3">
                <v-col class="text-end">
                    <v-btn
                        prepend-icon="mdi-content-save-outline"
                        :loading="userForm.processing || passwordForm.processing"
                        :disabled="(!userForm.isDirty && !passwordForm.isDirty) || passwordForm.password !== passwordForm.password_confirmation"
                        text="Speichern"
                        color="primary"
                        @click="saveData"
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>
